<template>
	<div class="login-wrap">
		<div class="login">
			<div class="main-login">
				<div class="left-login">
					<div class="login-title">
						找回密码
						<br>
						<br>
						一站式好物电商服务
					</div>
				</div>
				<div class="right-login">
					<div class="login-tab">
						<div class="tablist">
							<div class="tab-container">
								<div class="tab-login-scroll">
									<div class="tab-login-nav">
										<div class="login-active login-tab-txt" >找回密码</div>
										<div class="login-animated" ></div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-login-content">
							<div class="login-tabpane">
								<div class="login-top">
									<el-form ref="formforget" status-icon :model="formforget" :rules="rules">
										<div class="mt1">
											<el-form-item prop="user" >
												<el-input prefix-icon="el-icon-user" size="medium" v-model="formforget.user" placeholder="手机号码"></el-input>
											</el-form-item>
										</div>
                                        <div class="mt1">
											<el-form-item prop="code">
												<el-input prefix-icon="el-icon-coffee-cup" class="login-yzm" size="medium" v-model="formforget.code" placeholder="验证码"></el-input>
												<el-button type="primary" style="background-color: #409EFF;border-color: #409EFF;" size="medium" @click="onSendSms()" class="send_sms">{{sendText}}</el-button>
											</el-form-item>
										</div>
										<div class="mt1">
											<el-form-item prop="password">
												<el-input prefix-icon="el-icon-lock" size="medium" v-model="formforget.password" placeholder="密码" :type="pwd_flag ? 'text' : 'password'">
                                                     <i slot="suffix" class="haowu" :class="[pwd_flag?'hw-xianshimima_02':'hw-yincangmima_01']" style="margin-top:8px;font-size:18px;" autocomplete="auto" @click="pwd_flag=!pwd_flag" />
                                                </el-input>
											</el-form-item>
										</div>
                                        <div class="mt1">
											<el-form-item prop="repeatpassword">
												<el-input prefix-icon="el-icon-lock" size="medium" v-model="formforget.repeatpassword" placeholder="确认密码" :type="re_pwd_flag ? 'text' : 'password'">
                                                    <i slot="suffix" class="haowu" :class="[re_pwd_flag?'hw-xianshimima_02':'hw-yincangmima_01']" style="margin-top:8px;font-size:18px;" autocomplete="auto" @click="re_pwd_flag=!re_pwd_flag" />
                                                </el-input>
											</el-form-item>
										</div>
										<div class="mt1">
											<el-button type="primary" style="background-color: #409EFF;border-color: #409EFF;width: 100%;font-size:20px;" size="medium"  native-type="button" @click="onSubmit('formforget')">立即修改<i class="el-icon-right el-icon--right"></i></el-button>
										</div>
										<div class="mt1">
											<router-link class="login-btn" :to="{path:'login'}">立即登录</router-link>
											<router-link class="forget-btn" :to="{path:'register'}">注册账号</router-link>
										</div>
									</el-form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {validateuser,validatepassword,validatecode} from "@/utils/validate"
import throttle from "lodash/throttle";
export default {
	data() {
        //验证重复密码
        var validaterepeatpassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formforget.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
      return {
		//表单
        formforget: {
			user:'',//手机号码
			password:'',
            repeatpassword:'',//重复密码
			code:'',//短信验证码
			sendnum:1,//发送短信次数
        },
		//规则
		rules: {
			  user: [
				{ required: true,validator: validateuser, trigger: 'blur' },
			  ],
			  password: [
				{ required: true, validator: validatepassword, trigger: 'blur' },
			  ],
              repeatpassword: [
                { required: true, validator: validaterepeatpassword, trigger: 'blur' },
			  ],
			  code:[
				{ required: true, validator: validatecode, trigger: 'blur' },
			]
		},
        timer: 0,
         pwd_flag:false,//控制密码显示与隐藏
        re_pwd_flag:false,//控制重复密码显示与隐藏
      }
    },
	computed: {
		//发送验证码倒计时
		sendText() {
			if (this.timer === 0) {
				return "发送验证码";
			} else {
				return `${this.timer}秒后重发`;
			}
		},
	},
	watch: {
		timer(num) {
			if (num > 0) {
				setTimeout(() => {
				this.timer = --num;
				}, 1000);
			}
    	},
	},

	methods:{
		//重置密码
		onSubmit:throttle(function(){
              var that = this;
			this.$refs.formforget.validate(async (valid) => {
			  if (valid) {
				if(this.formforget.sendnum>0){
                      try{
                            const { user,password,repeatpassword,code} = this.formforget
                            await this.$store.dispatch('getphoneForget',{mot:user,pwd:password,apwd:repeatpassword,yzm:code})
                            this.$message.success('修改密码成功！')
                            this.$router.replace('/login')
                      }catch(error){
                            this.$message.error(error.message)
                      }
				}else{
					this.$message.error('请先发送验证码');
				}
			  }else{
				   this.$message.error('请输入正确的信息');
			  }
			});
        },1000),
           
	   
		
		//发送验证码
		 onSendSms(){
		  this.$refs.formforget.validateField("user", err => {
			if (!err) {
                try{
                     this.$store.dispatch('getSmscode',{mot:this.formforget.user,type:3})  
                     this.timer=60;
					 this.formforget.sendnum+=1;   
                }catch(error){
                    this.$message.error(error.message)
                }
			}
		  });
		}
	},
}
</script>
<style  lang="less" scoped>
	.login-wrap{
		width: 100%;
		height: 100%;	
		position: absolute;
    	margin: -8px;	
		*{
			box-sizing: border-box;
		}
	}
	.login{
		height: 100%;
		min-height: 700px;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-image: url(//www.soso9.com/static/img/background1.png);
		.main-login{
			width: 920px;
			height: 600px;
			top: 50%;
			left: 50%;
			position: absolute;
			-webkit-transform: translate(-50%,-50%);
			-moz-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
			-o-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
			overflow: hidden;
			box-shadow: 0 0 15px rgb(0 138 233 / 15%);
			.left-login{
				float: left;
				width: 380px;
				height: 600px;
				border-radius: 10px 0 0 10px;
				background: url(//www.soso9.com/static/img/left-login.png) center center no-repeat;
				position: relative;
				padding: 0 78px;
				.login-title{
					font-size: 28px;
					color: #fff;
					letter-spacing: 9px;
					margin: 150px 0 80px 0;
				}
			}
			.right-login{
				width: 540px;
				height: 600px;
				padding: 50px 60px;
				background-color: #fff;
				position: relative;
				float: left;
				border-radius: 0 10px 10px 0;
				.login-tab{
					box-sizing: border-box;
					margin: 0;
					padding: 0;
					color: rgba(0,0,0,.65);
					font-size: 14px;
					font-variant: tabular-nums;
					line-height: 1.5;
					list-style: none;
					font-feature-settings: "tnum";
					position: relative;
					overflow: hidden;
					zoom: 1;
					.tablist{
						margin: 0 0 16px;
						border-bottom: 1px solid #e8e8e8;
						outline: none;
						-webkit-transition: padding .3s cubic-bezier(.645,.045,.355,1);
						transition: padding .3s cubic-bezier(.645,.045,.355,1);
						.tab-container{
							position: relative;
							box-sizing: border-box;
							margin-bottom: -1px;
							overflow: hidden;
							font-size: 14px;
							line-height: 1.5;
							white-space: nowrap;
							zoom: 1;
							.tab-login-scroll{
								margin-bottom: -1px;
    							overflow: hidden;
								.tab-login-nav{
									user-select:none;
									position: relative;
									display: inline-block;
									box-sizing: border-box;
									margin: 0;
									padding-left: 0;
									list-style: none;
									-webkit-transition: -webkit-transform .3s cubic-bezier(.645,.045,.355,1);
									transition: -webkit-transform .3s cubic-bezier(.645,.045,.355,1);
									transition: transform .3s cubic-bezier(.645,.045,.355,1);
									transition: transform .3s cubic-bezier(.645,.045,.355,1),-webkit-transform .3s cubic-bezier(.645,.045,.355,1);
								}
								.login-tab-txt{
									position: relative;
									display: inline-block;
									box-sizing: border-box;
									height: 100%;
									margin: 0 32px 0 0;
									padding: 12px 16px;
									text-decoration: none;
									cursor: pointer;
									-webkit-transition: color .3s cubic-bezier(.645,.045,.355,1);
									transition: color .3s cubic-bezier(.645,.045,.355,1);
									font-size: 18px;
								}
								.login-active{
									font-size: 20px;
									padding: 20px 16px;
									color: #1890ff;
									font-weight: 500;
								}
								.login-animated{
									display: block;
									transform: translate3d(0px, 0px, 0px);
									width: 152px;
									position: absolute;
									bottom: 1px;
									left: 0;
									z-index: 1;
									box-sizing: border-box;
									height: 2px;
									background-color: #1890ff;
									-webkit-transform-origin: 0 0;
									transform-origin: 0 0;
									transition: transform .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1),left .3s cubic-bezier(.645,.045,.355,1),-webkit-transform .3s cubic-bezier(.645,.045,.355,1);
								}
							}
						}
					}
				}
				.tab-login-content{
					width: 100%;
					display: -webkit-box;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					flex-direction: row;
					-webkit-transition: margin-left .3s cubic-bezier(.645,.045,.355,1);
					transition: margin-left .3s cubic-bezier(.645,.045,.355,1);
					will-change: margin-left;
					margin-left: 0%;
					.login-tabpane{
						flex-shrink: 0;
						width: 100%;
						opacity: 1;
						-webkit-transition: opacity .45s;
						transition: opacity .45s;
						.login-top{
							margin-top: 15px;
						}
					}
					.mt1{
						margin-bottom: 2rem;
					}
				}	
				.login-yzm{
					display: inline-block;
					width: 55%;
				}
				.login-yzm-img{
					vertical-align: middle;
					width: 120px;
					height: 36px;
					padding-left: 20px;
					cursor: pointer;
				}
				.send_sms{
					width: 40%;
					margin-left: 5%;
				}
				.forget-btn{
					float: right;
    				color: #999;
					text-decoration:none;
				}
				.login-btn{
					color: #999;
					text-decoration:none;
				}
				.register-btn{
					float: right;
					color: #999;
					text-decoration:none;
				}
			}
		}
	}
</style>